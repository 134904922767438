import axios from 'axios';
//import Bowser from "bowser"
import { decode } from 'jsonwebtoken';
import { env } from '../env.js';

const instance = axios.create({
    baseURL: `${env.REACT_APP_API_URL}/api/v2`,
    timeout: 90000,
    headers: {
        'Content-Type': 'application/json',
        /*"browser-ra": JSON.stringify(Bowser.getParser(window.navigator.userAgent)),*/
        "Access-Control-Max-Age": 600
    }
});

instance.interceptors.request.use(
    config => {
      const token = localStorage.getItem('token');
      if (token) {
        // Decoded token e verificar se precisa ou não de 2fa
        const decodedToken = decode(token);

        if (decodedToken.needTwoFactorAuthentication && !decodedToken.twoFactorAuthenticated) {
          window.location.href = '/login';
          return Promise.reject('Autenticação de dois fatores necessária');
        }
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => Promise.reject(error)
);

export default instance;