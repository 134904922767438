import instance from './instancev2'

export function getDash(date, channel = null, department = null, user = null) {
	return instance.get(
		`/dash/getData/${date ? `?date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getAttendance(
	date,
	channel = null,
	department = null,
	user = null
) {
	return instance.get(
		`/dash/getAttendance/${date ? `?date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getAttendanceByChannel(
	date,
	channel = null,
	department = null,
	user = null
) {
	return instance.get(
		`/dash/getAttendanceByChannel/${date ? `?date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getAgents(
	date,
	channel = null,
	department = null,
	user = null
) {
	return instance.get(
		`/dash/getAgents/${date ? `?date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getDepartments(
	date,
	channel = null,
	department = null,
	user = null
) {
	return instance.get(
		`/dash/getDepartments/${date ? `?date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getProtocolByHour(
	date,
	channel = null,
	department = null,
	user = null
) {
	return instance.get(
		`/dash/getProtocolByHour/${date ? `?date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getAttendanceTime(
	date,
	channel = null,
	department = null,
	user = null
) {
	return instance.get(
		`/dash/getAttendanceTime/${date ? `?date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getFlow(date, channel = null, department = null, user = null) {
	return instance.get(
		`/dash/flow/${date ? `?date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getTabs(date, channel = null, department = null, user = null) {
	return instance.get(
		`/dash/getTabs/${date ? `?date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getTickets(
	date,
	channel = null,
	department = null,
	user = null
) {
	return instance.get(
		`/dash/getTickets/${date ? `?date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getMonitoring(filter) {
	return instance.get(
		`/dash/getMonitoring/${Array.isArray(filter?.date) ? `?date=${JSON.stringify(filter.date)}` : ''}${Array.isArray(filter?.user) ? `&users=${JSON.stringify(filter.user)}` : ''}${Array.isArray(filter?.type_user) ? `&profiles=${JSON.stringify(filter.type_user)}` : ''}`
	)
}

export function getAnalytic() {
	return instance.get(`/dash/getAnalytic`)
}

export function getAnalyticTicket(
	date,
	channel = null,
	department = null,
	user = null,
	offset,
	rows,
	history_phase
) {
	return instance.get(
		`/dash/tickets_analytic?offset=${offset}&rows=${rows}${date ? `&date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}&history_phase=${history_phase}`
	)
}
export function getAnalyticMonitoring(date, user = null, offset, rows) {
	return instance.get(
		`/dash/audit/analytic?offset=${offset}&rows=${rows}${date ? `&date=${JSON.stringify(date)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getAnalyticMonitoringReport({ date, user = null }) {
	return instance.get(
		`/dash/audit/export_analytic?${date ? `date=${JSON.stringify(date)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getBillingReport({ date, department = null }) {
	return instance.get(
		`/dash/billing_report?${date ? `date=${JSON.stringify(date)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}`
	)
}

export function getAnalyticOccurrence(
	date,
	channel = null,
	department = null,
	user = null,
	offset,
	rows
) {
	return instance.get(
		`/dash/occurrences?offset=${offset}&rows=${rows}${date ? `&date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getAnalyticWatson({ date, department = null, offset, rows }) {
	return instance.get(
		`/dash/get_ia_analisys?offset=${offset}&rows=${rows}${date ? `&date=${JSON.stringify(date)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}`
	)
}

export function getAnalyticIa({ date, department = null, offset, rows }) {
	return instance.get(
		`/dash/get_ia_interactions?offset=${offset}&rows=${rows}${date ? `&date=${JSON.stringify(date)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}`
	)
}

export function getAnalyticLogs({
	date,
	user = null,
	offset,
	rows,
	id_type = null,
}) {
	return instance.get(
		`/dash/audit/analytic_logs_action?offset=${offset}&rows=${rows}${date ? `&date=${JSON.stringify(date)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}${id_type ? `&id_type=${id_type}` : ''}`
	)
}

export function getAnalyticTransfer(
	date,
	channel = null,
	department = null,
	user = null,
	offset,
	rows
) {
	return instance.get(
		`/dash/protocol_transfer?offset=${offset}&rows=${rows}${date ? `&date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getDashShift(
	date,
	channel = null,
	department = null,
	user = null,
	offset = null,
	rows = null
) {
	return instance.get(
		`/dash/protocols_shift${date ? `?date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}${offset ? `&offset=${JSON.stringify(offset)}` : ''}${rows ? `&rows=${JSON.stringify(rows)}` : ''}`
	)
}

export function getDashCRM(
	date,
	channel = null,
	department = null,
	user = null
) {
	return instance.get(
		`/dash/crm${date ? `?date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}
export function getDashRankingUser(
	date,
	channel = null,
	department = null,
	user = null
) {
	return instance.get(
		`/dash/ranking_operators${date ? `?date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}
export function getDashTimeMessage(
	date,
	channel = null,
	department = null,
	user = null
) {
	return instance.get(
		`/dash/time_message_stats${date ? `?date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getDashSla(
	date,
	channel = null,
	department = null,
	user = null,
	offset,
	rows
) {
	return instance.get(
		`/dash/protocols_sla${date ? `?date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}${offset ? `&offset=${JSON.stringify(offset)}` : ''}${rows ? `&rows=${JSON.stringify(rows)}` : ''}`
	)
}

export function getDashProtocol(
	date,
	channel = null,
	department = null,
	user = null,
	offset,
	rows
) {
	return instance.get(
		`/dash/protocols?offset=${offset}&rows=${rows}${date ? `&date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getDashProtocolAll({
	date,
	channel = null,
	department = null,
	user = null,
}) {
	return instance.get(
		`/dash/protocols_all?${date ? `date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getAnalyticReport({
	date,
	channel = null,
	department = null,
	user = null,
}) {
	return instance.get(
		`/dash/analytic_report?${date ? `date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getOccurrenceReport({
	date,
	channel = null,
	department = null,
	user = null,
}) {
	return instance.get(
		`/dash/occurrences_report?${date ? `date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getTicketReport({
	date,
	channel = null,
	department = null,
	user = null,
	history_phase = false,
}) {
	return instance.get(
		`/dash/tickets_report?${date ? `date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}&history_phase=${history_phase}`
	)
}

export function getTransferReport({
	date,
	channel = null,
	department = null,
	user = null,
}) {
	return instance.get(
		`/dash/analytic_protocol_transfer?${date ? `date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getMessageReport({
	date,
	channel = null,
	department = null,
	user = null,
}) {
	return instance.get(
		`/dash/message_report?${date ? `date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getSmartFlowReport({ date }) {
	return instance.get(
		`/dash/flow/export_interaction_status?${date ? `date=${JSON.stringify(date)}` : ''}`
	)
}

export function getNpsReport({
	date,
	channel = null,
	department = null,
	user = null,
}) {
	return instance.get(
		`/dash/nps_report?${date ? `date=${JSON.stringify(date)}` : ''}${channel ? `&channel=${JSON.stringify(channel)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}`
	)
}

export function getWatsonReport({ date, department }) {
	return instance.get(
		`/dash/export_ia_analisys?${date ? `date=${JSON.stringify(date)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}`
	)
}

export function getIaReport({ date, department }) {
	return instance.get(
		`/dash/export_ia_interactions?${date ? `date=${JSON.stringify(date)}` : ''}${department ? `&department=${JSON.stringify(department)}` : ''}`
	)
}

export function getLogsReport({ date, user, id_type }) {
	return instance.get(
		`/dash/audit/export_analytic_logs_action?${date ? `date=${JSON.stringify(date)}` : ''}${user ? `&users=${JSON.stringify(user)}` : ''}${id_type ? `&id_type=${id_type}` : ''}`
	)
}

export function getCustomReport({ screen_location }) {
	return instance.get(`/dash/custom/screen/${screen_location}`)
}

export function getDashReport({ date }) {
	return instance.get(
		`/dash/agents_report?${date ? `date=${JSON.stringify(date)}` : ''}`
	)
}
