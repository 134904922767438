import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { i18nState } from 'redux-i18n'
import { auth } from './auth'
import { blockedWord } from './blockedWord'
import { breaks } from './break'
import { campaign } from './campaign'
import { category } from './category'
import { channel } from './channel'
import { chat } from './chat'
import { config } from './config'
import { contact } from './contact'
import { crmBusiness, crmTemplate } from './crm'
import { dash } from './dash'
import { department } from './department'
import { distribuition } from './distribuition'
import { email } from './email'
import { emailIMap } from './emailIMap'
import { emailMarketing } from './emailmarketing'
import { emailService } from './emailService'
import { faq } from './faq'
import { flow, template } from './flow'
import { flowV2 } from './flowv2'
import { fontSize } from './fontSize'
import { group, groupSelect } from './group'
import { handle } from './handle'
import { instagram } from './instagram'
import { landingPage } from './landingPage'
import { nps } from './nps'
import {
	protocolAll,
	protocolAllBot,
	protocolAllHuman,
	protocolClosed,
	protocolClosedBot,
	protocolClosedHuman,
	protocolCount,
	protocolCountType,
	protocolDepartment,
	protocolFlow,
	protocolFlowBot,
	protocolFlowHuman,
	protocolLiveStream,
	protocolNPS,
	protocolNPSBot,
	protocolNPSHuman,
	protocolOpen,
	protocolOpenBot,
	protocolOpenHuman,
	protocolOpenWaiting,
	protocolOpenWaitingClient,
	protocolOpenWaitingOperator,
	protocolSelect,
	protocolVideoVoiceQueue,
	protocolVideoVoiceRun,
} from './protocol'
import { siebel } from './siebel'
import { sla, unitTime } from './sla'
import { sms } from './sms'
import { soul } from './soul'
import { tab } from './tab'
import { ticket } from './ticket'
import { user, userStatus } from './user'
import { watson } from './watson'
import { whatsApp } from './whatsApp'
import { workflow, workspace } from './workflow'

export default history =>
	combineReducers({
		i18nState,
		router: connectRouter(history),
		auth,
		campaign,
		category,
		channel,
		chat,
		config,
		contact,
		blockedWord,
		breaks,
		dash,
		distribuition,
		department,
		email,
		emailService,
		emailMarketing,
		emailIMap,
		faq,
		tab,
		template,
		handle,
		instagram,
		flow,
		group,
		groupSelect,
		crmBusiness,
		crmTemplate,
		protocolOpen,
		protocolAll,
		protocolAllBot,
		protocolAllHuman,
		protocolOpenBot,
		protocolOpenHuman,
		protocolOpenWaiting,
		protocolFlow,
		protocolFlowBot,
		protocolFlowHuman,
		protocolOpenWaitingClient,
		protocolOpenWaitingOperator,
		protocolLiveStream,
		protocolNPS,
		protocolNPSBot,
		protocolNPSHuman,
		protocolClosed,
		protocolClosedBot,
		protocolCountType,
		protocolClosedHuman,
		protocolVideoVoiceQueue,
		protocolVideoVoiceRun,
		protocolCount,
		protocolDepartment,
		protocolSelect,
		sms,
		sla,
		unitTime,
		user,
		userStatus,
		watson,
		whatsApp,
		landingPage,
		nps,
		fontSize,
		ticket,
		siebel,
		soul,
		flowV2,
		workspace,
		workflow,
	})
